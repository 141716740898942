import Api from "../../../Shared/services/api";

const baseUrl = "UserInvitation";

export default {
  get() {
    return Api().get(baseUrl);
  },
  getById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  update(entity) {
    if (entity.id) 
      return Api().put(baseUrl, entity);
    else 
      return this.post(entity);
  },
  post(toSend) {
    return Api().post(baseUrl, toSend);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
};
