export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    width: "64px",
    sortable: false,
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "User Name",
    value: "firstName",
    order: 2,
    hidable: false,
    hidden: false
  },
  {
    text: "Email",
    value: "email",
    order: 3,
    hidable: false,
    hidden: false
  },
  {
    text: "Status",
    value: "status",
    order: 4,
    hidable: false,
    hidden: false
  },
];
